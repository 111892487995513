body {
  font-family: 'Noto Sans Thai', sans-serif;
  /* font-family: 'Bai Jamjuree', sans-serif; */
  color: #282828;
  font-size: 16px;
}

.h1_highlight {
  font-family: 'Noto Sans Thai', sans-serif;
  color: #fff;
  font-size: 40px;
  line-height: 52px;
  /* font-weight: bold; */
}

.bg-b1 {
  background-color: rgb(197, 214, 231) !important;
}

.bg-b2 {
  background-color: rgb(124, 171, 216) !important;
}

.bg-b3 {
  background-color: rgb(68, 132, 197) !important;
}

.bg-g1 {
  background-color: rgb(223, 223, 223) !important;
}

.div_folder_state_icon {
  font-size: 1.5rem;
  text-align: right;
}
.div-back-btn {
  position: fixed;
  right: 50px;
  bottom: 30px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}
.assignment-folder-idx {
  position: absolute;
  left: -30px;
  top: 10px;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 42px;
  background-color: white;
  border-radius: 24px;
  border-width: 3px;
  border-color: #feb81a;
  border-style: solid;
  font-weight: bold;
  vertical-align: middle;
  font-size: 1.25rem;
}
#top-menu-container {
  height: 80px;
  background-color: rgb(31, 37, 125);
}

#top-menu-slogan-logo {
  height: 100%;
  width: 96px;
}

#top-menu-user {
  color: white;
}
#top-menu-slogan-title_2 {
  font-size: 0.8rem;
  font-weight: bold;
  color: blue;
  text-shadow: 1px 1px 8px #555555;
}

#top-menu-slogan-title_1 {
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 1px 1px 8px #555555;
  letter-spacing: 2px;
}

#main-container-border {
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #555555;
  padding: 10px;
  display: flex;
  align-items: stretch;
}

#main-container-border-inner {
  min-height: 100%;
  width: 100%;
  background-color: #eeeeee;
  margin: 0px;
  padding: 10px;
  border-radius: 15px;
}

#intro_login_btn {
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.intro_logo {
  width: 100%;
  height: 70%;
  padding: 128px 20% 128px 20%;
}

#intro_login_btn button {
  background-color: #fff;
  border-radius: 20px;
  font-size: 1.5rem;
  border-color: #feb81a;
  border-width: 3px;
  width: 175px;
}

#intro_login_btn button:hover {
  font-weight: bold;
  color: #0d6efd;
}

#intro_container {
  width: 100%;
  height: 100vh;
}

#intro_logo {
  position: fixed;
  top: 50px;
  right: 20%;
  width: 300px;
  height: 200px;
}

#intro_title {
  width: 80%;
  top: 40%;
  position: fixed;
  right: 20%;
}

hr {
  height: 2px !important;
  opacity: 1;
  margin-left: 50px;
  margin-right: 50px;
}

#left-content {
  width: 200px;
  min-width: 200px;
}

#main-content {
  min-width: 350px;
  width: calc(100vw - 200px);
}

#left_user_img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

#left_menu {
  height: calc(100vh - 450px);
}

#top_user_img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.txt-w {
  color: #ffffff;
}

.dashboard-course {
  border-radius: 15px;
  min-width: 350px;
  /* width:300px; */
}

.dashboard-course .card-header {
  padding: 0px;
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgb(31, 37, 125);
  border-radius: 15px 15px 0px 0px;
}

.bg-page {
  background-color: rgb(31, 37, 125);
}

.title-page {
  font-family: 'Noto Sans Thai', sans-serif;
}

.rounded-xl {
  border-radius: 15px;
}
.progress_detail {
  font-size: 1rem;
}

.list-course {
  border-radius: 15px;
  width: 300px;
  min-height: 225px;
}

.course-list-img {
  height: 100px;
}

.course-detail-head {
  min-height: 200px;
}

.course-detail-head img {
  position: absolute;
  right: 0px;
  top: 0px;
  max-height: 100%;
}

.fs-xs {
  font-size: 0.9rem;
}

.inst-picture {
  /* height:64px; */
  height: 100px;
  width: 100px;
}

#video_container {
  height: 350px;
  width: 100%;
}

.div_learning_status {
  width: 32px;
}

.div_content_type {
  width: 32px;
}

.div_assignment[variant='active'] {
  font-weight: bold;
  text-decoration: underline;
}

.div_assignment[variant='disable'] {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.div_assignment[variant='disable']:hover {
  opacity: 0.5;
  cursor: not-allowed !important;
  text-decoration: none;
}

.div_assignment {
  cursor: pointer;
}

.div_assignment:hover {
  text-decoration: underline;
}

.assignment_list_container {
  height: calc(100vh - 520px);
  min-height: 250px;
  overflow: auto;
}

.web-container {
  min-height: 100vh;
  background-color: #585858;
}

.bg-lyellow {
  background-color: #feb81a !important;
}

.web-menu div.list-group-item {
  color: #065fb9 !important;
  font-weight: bold;
  border: none !important;
  cursor: pointer;
}

.web-menu div.list-group-item a {
  color: #065fb9 !important;
  text-decoration: none !important;
}

.web-menu div.list-group-item.active {
  background-color: #feb81a33 !important;
}

.web-title-container {
  font-size: calc(1.325rem + 0.9vw);
  background-color: #065fb9;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  color: #fefefe;

  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  padding-top: 0.25rem;
  padding-left: 2rem;
  padding-bottom: 0.75rem;
}

.document-card-container {
  margin-left: 36px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding-left: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  font-weight: bold;
  margin-bottom: 16px;
}

.document-card-container:nth-child(odd) {
  background: #065fb977;
}
.document-card-container:nth-child(odd) .document-card-attachment {
  background: #065fb977;
  border: 1px solid #585858;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  font-weight: normal;
  padding-left: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.document-card-container:nth-child(even) {
  background: #f9f9f977;
}

.document-card-container:nth-child(even) .document-card-attachment {
  background: #dfdfdf77;
  border: 1px solid #585858;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  font-weight: normal;
  padding-left: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.document-card-logo {
  position: absolute;
  width: 48px;
  height: 48px;
  left: -24px;
}

.document-card-attachment-item {
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
}

.document-card-attachment-item img {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.document-card-attachment-item a {
  color: black;
  text-decoration: none;
}

/* Create by IM */
.list-group-item.active {
  background-color: #1f257d;
  border-color: #1f257d;
}

.btn-add {
  font-size: 20px;
  font-family: 'Noto Sans Thai', sans-serif;
}

.page-link {
  font-size: 16px !important;
}

.text-b {
  font-family: 'Noto Sans Thai', sans-serif;
  max-width: 500px;
  word-break: break-word;
}

.text-m {
  font-family: 'Noto Sans Thai', sans-serif;
}

.dashboard-gray {
  filter: grayscale(1);
}
.b-center {
  /* justify-content: space-between; */
  justify-content: center;
}

.n-instructor {
  margin-bottom: 0;
}

.text-name {
  word-break: break-word;
  width: 350px;
}

.swal2-styled {
  font-size: 1.2em !important;
}

@media only screen and (max-width: 1200px) {
  .text-b {
    max-width: 300px;
    font-size: 1.5rem;
  }
}
